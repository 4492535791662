<script>
//import DeviceInfos from "./device-infos";
import DeviceInfosUpdated from "./device-infos-updated";
import {
  devicesMethods,
  alertsMethods,
  provincesMethods,
  devicesComputed,
  alertsComputed,
  provincesComputed,
} from "@/state/helpers";
import L from "leaflet";
import {
  LMap,
  //LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LTileLayer,
} from "vue2-leaflet";
//import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
/**
 * Map component
 */

export default {
  props: [
    "devices",
    "causes",
    "actions",
    "states",
    "vaccintypes",
    "provinces",
    "loader",
  ],
  mounted() {
    this.provincesPositions = this.provinces;
    this.provinces.map((obj) => {
      this.provincesList.push({ value: obj.id, text: obj.name });
    });
  },
  components: {
    //DeviceInfos,
    DeviceInfosUpdated,
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
    //"l-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      filterType: 1,
      noFilter: null,
      serialFilter: null,
      filterStatus: false,
      mapZoom: 6,
      mapCenter: [27.105314, -7.684968],
      devicesPositions: [],
      eventsData: [],
      alertsData: [],
      provincesPositions: [],

      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),

      clusterOptions: {
        showCoverageOnHover: false,
      },

      imageDevice: require("@/assets/images/iconDevice.svg"),

      imageDeviceGreen: require("@/assets/images/pin_green.svg"),
      imageDeviceRed: require("@/assets/images/pin_red.svg"),
      imageDeviceGray: require("@/assets/images/pin_gray.svg"),
      imageDeviceBlack: require("@/assets/images/pin_black.svg"),
      imageDeviceOrange: require("@/assets/images/pin_orange.svg"),

      imageProvince: [
        require("@/assets/images/iconProvince1.svg"),
        require("@/assets/images/iconProvince2.svg"),
        require("@/assets/images/iconProvince3.svg"),
        require("@/assets/images/iconProvince4.svg"),
        require("@/assets/images/iconProvince5.svg"),
      ],

      imageClear: require("@/assets/images/clearmap.svg"),
      imageReset: require("@/assets/images/resetzoom.svg"),
      zoomIn: require("@/assets/images/zoomin.svg"),
      zoomOut: require("@/assets/images/zoomout.svg"),

      provinceFilter: null,
      provincesList: [{ value: null, text: "Province", disabled: true }],

      //url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      //url: "http://maps.google.com/maps?file=api&amp;v=2&amp;hl=en&amp;sensor=true",
      staticAnchor: [16, 10],
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      deviceInfos: {},
      deviceEvents: {},

      selectedVaccintypes: [],
      vaccintypesData: [],

      pniDetails: [],
      autresDetails: [],
      insulineDetails: [],

      covidDetails: [],

      loaderDevice: false,
      loaderAlerts: false,
    };
  },
  computed: {
    ...devicesComputed,
    ...alertsComputed,
    ...provincesComputed,
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    ...devicesMethods,
    ...alertsMethods,
    ...provincesMethods,
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      });
      return obj;
    },
    changeProvinces(vaccinTypes) {
      let filterVc = [];
      vaccinTypes.map((obj) => {
        if (obj.selected) filterVc.push(obj.id);
      });
      this.getProvincesBy({ vaccinTypes: filterVc, limit: 100 }).then(
        (provinces) => {
          this.provincesPositions = provinces.results;
        }
      );
    },
    showDevices({ province, serial }) {
      let vaccins = this.vaccintypesData
        .filter((obj) => obj.selected == true)
        .map((obj) => {
          return obj.id;
        });
      if (province) {
        let params = this.cleanObject({
          province: province,
          vaccinTypes: vaccins.length > 0 ? vaccins : null,
          limit: 1000,
        });
        this.getDevicesBy(params).then((devices) => {
          /* this.mapCenter = [devices.results[0].lat, devices.results[0].lng]
          this.mapZoom = 8; */
          if (devices.results.length <= 0) {
            this.makeToast("Alert Error", "No devices", "danger");
          } else {
            const mapComponent = this.$refs.map;
            let start = [devices.results[0].lat, devices.results[0].lng];
            let end = [devices.results[0].lat, devices.results[0].lng];
            mapComponent.fitBounds([start, end], { maxZoom: 8 });

            this.provincesPositions = this.provinces;
            this.provincesPositions = this.provincesPositions.filter(
              (obj) => obj.id != province
            );
          }
        });
      }
      if (serial) {
        let params = this.cleanObject({
          serial: serial,
          vaccinTypes: vaccins.length > 0 ? vaccins : null,
        });
        this.getDevicesBy(params);
      }
    },
    passDevice(device) {
      this.loaderDevice = true;
      this.loaderAlerts = true;
      let card = document.getElementById("deviceInfosCard");
      card.style.display= "block";
      this.getDeviceInfos(device)
        .then((device) => {
          let params = {
            serial: device.serial,
            limit: 100,
            sortBy: "createdAt:desc",
          };
          this.getEventsBy(params).then(() => {
            this.loaderDevice = false;
            this.getAlertsBy({
              serial: device.serial,
              limit: 5,
              sortBy: "createdAt:desc",
            }).then(() => {
              this.loaderAlerts = false;
            });
          });
        })
        .catch((error) => {
          this.makeToast("Alert Error", error, "danger");
        });
    },
    search() {
      if (this.serialFilter) {
        this.showDevices({ serial: this.serialFilter });
        this.noFilter = null;
      } else if (this.provinceFilter) {
        this.provincesPositions.map((obj) => {
          if (obj.id === this.provinceFilter) {
            const mapComponent = this.$refs.map;
            let start = [obj.lat, obj.lng];
            let end = [obj.lat, obj.lng];
            mapComponent.fitBounds([start, end], { maxZoom: 11 });
          }
        });
        this.noFilter = null;
      } else {
        this.noFilter = true;
      }
    },
    setZoomIn() {
      this.mapZoom = this.mapZoom + 1;
    },
    setZoomOut() {
      this.mapZoom = this.mapZoom - 1;
    },
    setResetZoom() {
      let center = [27.105314, -7.684968];
      const mapComponent = this.$refs.map;
      let start = center;
      let end = center;
      mapComponent.fitBounds([start, end], { maxZoom: 6 });
    },
    updateZoom(event) {
      this.mapZoom = event;
    },
    setClearMap() {
      this.clearDevices();
      this.provincesPositions = this.provinces;
      this.setResetZoom();
    },
    clear() {
      this.filterStatus = false;
      this.devicesPositions = this.devices;
    },
    changeFilterType(type) {
      this.serialFilter = null;
      this.provinceFilter = null;
      this.filterType = type;
    },
    changeStatusVaccin(id) {
      this.vaccintypesData = this.vaccintypesData.map((obj) => {
        if (obj.id == id) {
          return { ...obj, selected: !obj.selected };
        } else return obj;
      });
      this.setClearMap();
      this.changeProvinces(this.vaccintypesData);
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    getDevices(newVal) {
      this.devicesPositions = newVal.results;
    },
    getEvents(newVal) {
      this.eventsData = newVal.results;
    },
    getAlerts(newVal) {
      this.alertsData = newVal.results;
    },
    getDevice(newVal) {
      this.deviceInfos = newVal;
    },
    devices(newVal) {
      this.devicesPositions = newVal;
      let length = newVal.length;
      if (length == 1) {
        const mapComponent = this.$refs.map;
        let start = [newVal[0].lat, newVal[0].lng];
        let end = [newVal[length - 1].lat, newVal[length - 1].lng];
        mapComponent.fitBounds([start, end], { maxZoom: 12 });
      }
    },
    provinces(newVal) {
      this.provincesPositions = newVal;
      let provincesData = newVal;
      provincesData.map((obj) => {
        this.provincesList.push({ value: obj.id, text: obj.name });
      });
    },
    vaccintypes(newVal) {
      this.vaccintypesData = newVal.map((obj) => {
        return { ...obj, selected: false };
      });
      newVal.forEach((obj) => {
        if (obj.name == "PNI")
          this.vaccintypesData[0] = { ...obj, selected: false };
        else if (obj.name == "Autres vaccins...")
          this.vaccintypesData[1] = { ...obj, selected: false };
        else if (obj.name == "Insuline")
          this.vaccintypesData[2] = { ...obj, selected: false };
        else if (obj.name == "Covid")
          this.vaccintypesData[3] = { ...obj, selected: false };
        else if (obj.name == "Covid (1/2)")
          this.vaccintypesData[4] = { ...obj, selected: false };
        else this.vaccintypesData[4] = { ...obj, selected: false };
      });
      this.pniDetails = [
        "BCG",
        "HB",
        "PENTAVAC",
        "RR",
        "VPO",
        "VPI",
        "ANTI PNEUMO",
        "DTCP",
        "TETANOS (TD)",
        "ROTASIIL",
        "HIB",
        "ROR",
        "VACCIN ANTIPOLIOMYELITIQUE",
        "ANTI POLIO",
      ];
      this.autresDetails = [
        "LATANOPROST",
        "OXYTOCINE PANPHARMA",
        "RABIQUE-PASTEUR",
        "VACCIN CONTRE LA RAGE",
        "VACCIN CONTRE LA MENINGITE",
      ];
      this.insulineDetails = ["MIXTARD", "INSULTARD", "NPH", "INSUMAN BASAL"];

      this.covidDetails = ["SINOPHARM", "PFIZER"];
    },
  },
  beforeDestroy() {
    //this.$refs.map.removeLayer(this.$refs.markerCluster)
  },
};
</script>

<template>
  <div>
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div style="height: 100%">
      <span
        class="searchInput mt-2"
        style="position: absolute; z-index: 999;width: 1px; left: 3%; text-overflow: ellipsis;"
      >
        <div class="btnVaccins">
          <b-button
            v-for="(item, index) in vaccintypesData"
            :variant="!item.selected ? 'primary' : 'danger'"
            class="mt-1 ml-2 btnVac"
            :key="index"
            @click="changeStatusVaccin(item.id)"
          >
            {{ item.name }}
          </b-button>
        </div>

        <div class="btnVaccinsTypes">
          <div style="display: flex; flex-direction: column; max-height: 50px">
            <b-button
              v-for="(item, index) in pniDetails"
              size="sm"
              variant="success"
              class="mt-1 ml-2"
              style="width: 130px; font-size: 10px; font-weight: bolder; border-color: #20775d; background-color: #20775d"
              :key="index"
            >
              {{ item }}
            </b-button>
          </div>
          <div style="display: flex; flex-direction: column; max-height: 50px">
            <b-button
              v-for="(item, index) in autresDetails"
              size="sm"
              variant="success"
              class="mt-1 ml-2"
              style="width: 130px; font-size: 10px; font-weight: bolder; border-color: #20775d; background-color: #20775d"
              :key="index"
            >
              {{ item }}
            </b-button>
          </div>
          <div style="display: flex; flex-direction: column; max-height: 50px">
            <b-button
              v-for="(item, index) in insulineDetails"
              size="sm"
              variant="success"
              class="mt-1 ml-2"
              style="width: 130px; font-size: 10px; font-weight: bolder; border-color: #20775d; background-color: #20775d"
              :key="index"
            >
              {{ item }}
            </b-button>
          </div>
          <div style="display: flex; flex-direction: column; max-height: 50px">
            <b-button
              v-for="(item, index) in covidDetails"
              size="sm"
              variant="success"
              class="mt-1 ml-2"
              style="width: 130px; font-size: 10px; font-weight: bolder; border-color: #20775d; background-color: #20775d"
              :key="index"
            >
              {{ item }}
            </b-button>
          </div>
          <!-- <div style="display: flex; flex-direction: column; max-height: 50px">
            <b-button
              v-for="(item, index) in covidDetails"
              size="sm"
              variant="success"
              class="mt-1 ml-2"
              style="width: 130px; font-size: 10px; font-weight: bolder; border-color: #20775d; background-color: #20775d"
              :key="index"
            >
              {{ item }}
            </b-button>
          </div> -->
        </div>
      </span>
      <div
        class="searchInput mt-2"
        style="position: absolute; z-index: 999; right: 3%"
      >
        <b-input-group>
          <b-form-input
            v-if="filterType == 1"
            id="serial"
            type="text"
            :placeholder="$t('textInput.enterSerial.text')"
            v-model="serialFilter"
            :state="noFilter ? false : null"
          ></b-form-input>
          <b-form-select
            v-if="filterType == 2"
            id="province"
            type="text"
            :options="provincesList"
            v-model="provinceFilter"
            :state="noFilter ? false : null"
          ></b-form-select>
          <b-input-group-append>
            <b-button variant="light" class="pt-1 pb-1" @click="search">
              <i class="ri-search-line"></i>
            </b-button>
            <b-button
              v-if="filterStatus"
              variant="warning"
              class="pt-1 pb-1"
              @click="clear"
            >
              <i class="ri-refresh-line"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div>
          <p
            v-if="filterType == 1"
            class="badge badge-info font-size-13"
            style="cursor: pointer"
            @click="changeFilterType(2)"
          >
            {{ $t("buttons.searchByProvince.text") }}
          </p>
          <p
            v-if="filterType == 2"
            class="badge badge-info font-size-13"
            style="cursor: pointer"
            @click="changeFilterType(1)"
          >
            {{ $t("buttons.searchBySerial.text") }}
          </p>
        </div>
      </div>
      <div
        style="
          margin-top: 210px;
          position: absolute;
          z-index: 999;
          width: 30px;
          right: 3%;
        "
      >
        <b-row>
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="imageClear"
              @click="setClearMap"
          /></b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="imageReset"
              @click="setResetZoom"
          /></b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="zoomIn"
              @click="setZoomIn"
          /></b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="zoomOut"
              @click="setZoomOut"
          /></b-col>
        </b-row>
      </div>

      <l-map
        style="height: 120vh"
        ref="map"
        :zoom="mapZoom"
        v-on:update:zoom="updateZoom($event)"
        :worldCopyJump="true"
        :min-zoom="5"
        :options="{ zoomControl: false }"
        :center="mapCenter"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        <l-marker
          :zIndexOffset="100"
          v-for="marker in provincesPositions"
          :key="marker.id"
          :draggable="false"
          :icon="icon"
          :lat-lng="{ lat: marker.lat, lng: marker.lng }"
          @click="showDevices({ province: marker.id })"
        >
          <l-icon :icon-anchor="staticAnchor">
            <!-- <img
              :width="24"
              :src="imageProvince[1]"
            /> -->
            <img
              v-if="marker.deviceCounter < 15"
              style="width: 20px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 15 && marker.deviceCounter < 30"
              style="width: 22px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 30 && marker.deviceCounter < 45"
              style="width: 24px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 45 && marker.deviceCounter < 60"
              style="width: 26px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 60 && marker.deviceCounter < 75"
              style="width: 28px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 75 && marker.deviceCounter < 90"
              style="width: 30px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 90 && marker.deviceCounter < 105"
              style="width: 32px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 105 && marker.deviceCounter < 120"
              style="width: 34px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 120 && marker.deviceCounter < 135"
              style="width: 36px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 135 && marker.deviceCounter < 150"
              style="width: 38px"
              :src="imageProvince[1]"
            />
            <img
              v-if="marker.deviceCounter >= 150"
              style="width: 40px"
              :src="imageProvince[1]"
            />
          </l-icon>
          <l-tooltip
            class="rounded text-secondary"
            :options="{
              interactive: 'true',
              direction: 'top',
            }"
          >
            <span style="font-weight: 550"
              >{{ $t("tooltips.name.text") }} :</span
            >
            {{ marker.name }}<br />
            <span style="font-weight: 550"
              >{{ $t("tooltips.devicesNumber.text") }} :</span
            >
            {{ marker.deviceCounter }}

            <!--<div>
               <table>
                <tr>
                  <td>
                    <p
                      style="
                        font-weight: bold;
                        font-size: 13px;
                        padding: 0px;
                      "
                    >
                      title:&nbsp;
                    </p>
                  </td>
                  <td>
                    <p
                      style="
                        font-size: 13px;
                        padding: 0px;
                      "
                    >
                      {{ marker.name }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style="font-size: 13px; font-weight: bold; padding: 0px;">
                      Devices number : &nbsp;
                    </p>
                  </td>
                  <td>
                    <p style="font-size: 13px; padding: 0px;">
                      {{ marker.deviceCounter }}
                    </p>
                  </td>
                </tr>
              </table> 
            </div>-->
          </l-tooltip>
        </l-marker>
        <!-- <l-marker-cluster ref="markerCluster" :options="clusterOptions"> -->
        <l-marker
          :zIndexOffset="100"
          v-for="marker in devicesPositions"
          :key="marker.id"
          :draggable="false"
          :lat-lng="{ lat: marker.lat, lng: marker.lng }"
          :icon="icon"
          @click="
            passDevice(marker.id)
            /*$router.push({
              name: 'device',
              params: { id: marker.id },
            })*/
          "
        >
          <!-- <l-icon
            :icon-anchor="iconAnchor"
            :icon-size="iconSize"
            :tooltipAnchor="tooltipAnchor"
            :icon-url="imageDevice"
          >
            <img
              v-if="marker.offline == false"
              :src="imageDeviceGreen"
            />
            <img
              v-if="
                marker.offline == true || marker.offline == null
              "
              :src="imageDeviceBlack"
            />
          </l-icon> -->
          <l-icon
            :icon-anchor="iconAnchor"
            :icon-size="iconSize"
            :tooltipAnchor="tooltipAnchor"
            :icon-url="imageDevice"
          >
            <img
              v-if="
                (marker.stockType == 'VIDE' || marker.alertState == true) &&
                marker.offline == false
              "
              :src="imageDeviceGreen"
            />
            <img
              v-if="
                (marker.stockType == '24H' || marker.stockType == '24 H') &&
                marker.alertState == false &&
                marker.offline == false
              "
              :src="imageDeviceRed"
            />
            <img
              v-if="
                marker.stockType == '-' ||
                marker.stockType == 'NON COMMUNIQUE' ||
                marker.offline == true
              "
              :src="imageDeviceGray"
            />
          </l-icon>
          <l-tooltip
            class="rounded text-secondary"
            :options="{
              interactive: 'true',
              direction: 'top',
            }"
          >
            <span style="font-weight: 550"
              >{{ $t("tooltips.name.text") }} :
            </span>
            {{ marker.name }}<br />
            <span style="font-weight: 550"
              >{{ $t("tooltips.stockType.text") }} :
            </span>
            <span
              class="badge font-size-12"
              :class="{
                'badge-danger':
                  `${marker.stockType}` === '24H' ||
                  `${marker.stockType}` === '24 H',
                'badge-warning':
                  `${marker.stockType}` === 'DEMI JOURNEE' ||
                  `${marker.stockType}` === 'Jusqu\'à 20h' ||
                  `${marker.stockType}` === 'jusqu\'à 16H',
                'badge-success': `${marker.stockType}` === 'VIDE',
                'badge-secondary':
                  `${marker.stockType}` === '-' ||
                  `${marker.stockType}` === 'NON COMMUNIQUE',
              }"
            >
              {{ marker.stockType }} </span
            ><br />
            <span style="font-weight: 550">Province : </span>
            {{ marker.province.name }}
            <br />
            <span style="font-weight: 550"
              >{{ $t("tooltips.status.text") }} :
            </span>
            <span class="badge font-size-12">
              <i
                v-if="marker.offline == false"
                class="text-success ri-checkbox-blank-circle-fill"
              ></i>
              <i
                v-if="marker.offline == true || marker.offline == null"
                class="text-secondary ri-checkbox-blank-circle-fill"
              ></i>
            </span>
            <br />
            <span style="font-weight: 550"
              >{{ $t("tooltips.vaccin.text") }} :
            </span>
            <span
              class="text-primary font-weight-bold p-1"
              v-for="(item, index) in marker.vaccinTypes"
              :key="index"
            >
              {{ item.name }}
            </span>
          </l-tooltip>
        </l-marker>
        <!-- </l-marker-cluster> -->
      </l-map>
    </div>
    <!-- <DeviceInfos
      v-bind:device="deviceInfos"
      :events="eventsData"
      :loader="loaderDevice"
    /> -->
    <DeviceInfosUpdated
      v-bind:device="deviceInfos"
      :events="eventsData"
      :alerts="alertsData"
      :causes="causes"
      :actions="actions"
      :states="states"
      :loader="loaderDevice"
      :loaderAlerts="loaderAlerts"
    />
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 760px) {
  .searchInput {
    top: 60px;
  }
}
@media (min-width: 1000px) {
  .btnVaccins, .btnVaccinsTypes {
    display: flex;
    flex-direction: row
  }
  .btnVac {
    min-width: 130px;
  }
}
@media (max-width: 1000px) {
  .btnVaccins {
    display: flex;
    flex-direction: column
  }
  .btnVaccinsTypes {
    display: none;
  }
  .btnVac {
    width: 85px;
  }
}
</style>